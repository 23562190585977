<template>
  <div class="animated fadeIn">

    <b-row>
      <div class="col-12">
        <b-card>
          <b-row class="mx-md-n3 mx-1">

            <div class="col-sm col-12">
              <b-input-group>
                <b-form-input
                  v-model="filter"
                  @keyup.enter="findAll"
                  placeholder="Procurar por..."
                />
                <b-input-group-append>
                  <b-button size="md" text="Button" variant="outline-info" @click.passive="findAll">
                    <i class="cui-magnifying-glass"></i>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </div>

            <!-- <div class="col-sm-auto col-12 pl-sm-0 pl-3">
              <b-button size="md" variant="success" @click.prevent="openNewProfileForm()" :disabled="saving" class="mobile-btn-100 mt-sm-0 mt-3">
                <i class="fa fa-plus"></i> Novo Perfil
              </b-button>
            </div> -->

          </b-row>
        </b-card>
      </div>
    </b-row>

    <b-row>
      <div class="col-12">
        <div class="card">
          <div class="card-body p-0">
            <div v-if="loadingRows" class="mb-5 pb-5">
              <loading-animation />
            </div>

            <div v-if="!loadingRows && !profiles.length">
              <div class="row bg-light mx-0 py-3">
                <div class="col text-center">
                  Nenhum Perfil encontrado
                </div>
              </div>
            </div>

            <div v-if="!loadingRows && profiles.length" class="mb-3">
              <div v-for="(profile, index) in profiles" class="row py-3 border mx-0" :key="index" :class="{ 'bg-light': index % 2 != 0 }">
                <div class="col">
                  <span class="font-weight-bold">Nome: </span>{{profile.name}}
                </div>
                <!-- <div class="col-auto">
                  <span class="font-weight-bold">Ativo: </span>
                  <span v-if="profile.active" class="badge badge-secondary">Sim</span>
                  <span v-else class="badge badge-danger">Não</span>
                </div> -->
                <div class="col-auto">
                  <b-button size="sm" @click.prevent="editItem(index)" :disabled="saving"
                    class="ml-sm-1 ml-0 mr-sm-1 mr-4">
                    <i class="fa fa-edit"></i> Editar
                  </b-button>
                </div>
              </div>
            </div>

            <nav>
              <b-pagination
                v-if="totalRows"
                align="center"
                :limit="10"
                :total-rows="totalRows"
                :per-page="perPage"
                prev-text="Anterior"
                next-text="Próximo"
                v-model="currentPage"
                @change="pageChanged"
                :disabled="loadingRows"/>
            </nav>
          </div>
        </div>
      </div>
    </b-row>

    <b-modal
      centered
      size="md"
      lazy
      :title="(currentEntity && currentEntity.id ? 'Editar' : 'Novo') + ' perfil '"
      header-bg-variant="info"
      footer-border-variant="info"
      v-model="showFormModal">
      <b-container v-if="currentEntity">
        <div v-if="loadingCurrentEntity || saving">
          <loading-animation />
        </div>

        <div v-else class="row align-items-center">

          <div class="col-sm col-12">
            <b-form-group
              label="Nome"
            >
              <b-form-input
                type="text"
                v-model="currentEntity.name"
              />
            </b-form-group>
          </div>

          <!-- <div class="col-sm-auto col-12">
            <div class="row">
              <div class="col-auto pr-0">
                <b-form-checkbox v-model="currentEntity.active"></b-form-checkbox>
              </div>
              <div class="col pl-0">
                <label>Ativo</label>
              </div>
            </div>
          </div> -->

          <div class="col-12">
            <div class="card">
              <div class="card-header">Operações</div>
              <div class="card-body">

                <div v-for="(op, index) in operations" :key="index" class="row">
                  <div class="col-auto pr-0">
                    <b-form-checkbox
                      v-model="currentEntityOperations[op.value]"
                      @change="operationChanged(op, $event)"
                    ></b-form-checkbox>
                  </div>
                  <div class="col pl-0">
                    <label>{{op.label}}</label>
                  </div>
                </div>

                <p class="w-100 text-justify text-muted">
                  <small>Se nenhuma operação for selecionada o perfil terá acesso à todas as operações</small>
                </p>

              </div>
            </div>
          </div>

        </div>
      </b-container>

      <template v-if="currentEntity" slot="modal-footer" slot-scope="{ ok, cancel }">
        <b-button variant="secondary" @click="closeFormModal">Cancelar</b-button>
        <b-button variant="primary" @click="save"
          :disabled="loadingCurrentEntity || saving">
          Salvar
        </b-button>
      </template>
    </b-modal>

  </div>
</template>

<script>
  import { httpClient } from '@/service'
  import { LoadingAnimation } from '@/components/loaders'
  import shared from '@/shared/shared'

  export default {
    name: 'profiles',
    components: { LoadingAnimation },

    data () {
      return {
        profiles: [],
        loadingRows: false,
        currentPage: 1,
        perPage: 10,
        totalRows: null,
        filter: '',
        saving: false,
        showFormModal: false,
        currentEntity: null,
        loadingCurrentEntity: false,
        editIndex: null,
        operations: [],
        currentEntityOperations: {}
      }
    },

    mounted: async function () {
      this.$store.commit('app/SET_CURRENT_PAGE', { title: 'Lista de Perfis', size: 4 });
      this.operations = shared.getOperations();
      this.findAll();
    },

    methods: {
      findAll() {
        let conditions = [];

        if (this.filter && this.filter.length) {
          conditions.push({
            field: 'name',
            conditionalOperator: 'LIKE',
            value: this.filter
          });
        }

        this.loadingRows = true;
        httpClient.post(
          `${process.env.VUE_APP_API_URL}profiles/select-and-where?page=${this.currentPage - 1}&size=${this.perPage}`, {
          select: ['id', 'ref', 'name'],
          where: {
            conditions: conditions
          }
        }).then(({ data }) => {
          this.loadingRows = false;
          this.profiles = data.data.content;
          this.totalRows = data.data.totalElements;
        })
        .catch((error) => {
          this.loadingRows = false;
          if (error.message) {
            this.$notify.error(error)
          }
          this.profiles = [];
        });
      },

      pageChanged(page) {
        this.currentPage = page;
        this.findAll();
      },

      openNewProfileForm() {
        this.currentEntity = {
          active: true,
          type: 'U'
        };
        this.currentEntityOperations = {};
        this.showFormModal = true;
      },

      closeFormModal() {
        this.editIndex = null;
        this.showFormModal = false;
      },

      editItem(index) {
        this.showFormModal = true;
        this.loadingCurrentEntity = true;
        httpClient.get(`${process.env.VUE_APP_API_URL}profiles/${this.profiles[index].id}`)
        .then(({ data }) => {
          this.loadingCurrentEntity = false;
          this.editIndex = index;
          this.currentEntity = data.data;
          this.loadOperations();
        })
        .catch((error) => {
          this.loadingCurrentEntity = false;
          if (error.message) {
            this.$notify.error(error)
          }
        });
      },

      save() {
        if (!this.currentEntity.name) {
          this.$notify.textError('Por favor informe o nome');
          return;
        }

        this.saveOperations();
        this.saving = true;
        httpClient.patch(`${process.env.VUE_APP_API_URL}profiles/${this.currentEntity.id}`, {
          name: this.currentEntity.name,
          //active: this.currentEntity.active,
          restrictions: this.currentEntity.restrictions,
        })
        .then(({ data }) => {
          this.saving = false;
          this.$notify.success('Perfil salvo com sucesso');

          if (this.editIndex !== null && this.editIndex > -1) {
            this.profiles[this.editIndex] = data.data;
          } else {
            this.profiles.push(data.data);
          }

          this.editIndex = null;
          this.currentEntity = {};
          this.showFormModal = false;
        })
        .catch((error) => {
          this.saving = false;
          if (error.message) {
            this.$notify.error(error)
          }
        });
      },

      operationChanged(op, checked) {
        if (op && !op.value) {
          this.operations.forEach(o => {
            if (o.value) {
              this.currentEntityOperations[o.value] = checked;
            }
          });

          let temp = this.currentEntityOperations;
          this.currentEntityOperations = {};
          this.currentEntityOperations = temp;
        }
      },

      loadOperations() {
        this.currentEntityOperations = {};

        if (
          this.currentEntity.restrictions &&
          this.currentEntity.restrictions.order &&
          this.currentEntity.restrictions.order.fiscal_operations
        ) {
          this.currentEntity.restrictions.order.fiscal_operations.forEach(key => {
            this.currentEntityOperations[key] = true;
          });
        }
      },

      saveOperations() {
        this.currentEntity.restrictions = this.currentEntity.restrictions || {};
        this.currentEntity.restrictions.order = this.currentEntity.restrictions.order || {};
        this.currentEntity.restrictions.order.fiscal_operations = [];

        for (let key in this.currentEntityOperations) {
          if (this.currentEntityOperations[key] && this.operations.find(o => o.value === key)) {
            this.currentEntity.restrictions.order.fiscal_operations.push(key);
          }
        }
      }
    }
  }
</script>

<style scoped>

</style>
